.main
    background-color: white
    border-radius: 5px
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1)
    padding: 10px
    h1
        color: darkred
        font-size: 24px
        font-weight: 500
        margin: 4px
        padding: 4px
        svg
            margin-right: 4px
            vertical-align: middle

.title
    display: flex
    div:nth-child(1)
        flex-grow: 1

    button
        margin-top: 10px
